<script setup>
    import { ref, onMounted, computed } from 'vue'
    import { Splide, SplideSlide } from '@splidejs/vue-splide';

    const itinerary = ref(null);
    const pageError = ref(null);
    const working = ref(false);

    async function fetchData() {
        working.value = true;
        pageError.value = false;

        fetch('/api/Itinerary/Details', { credentials: "include" })
            .then(response => response.json())
            .then(data => itinerary.value = data)
            .catch(error => {
                console.log(error);
                pageError.value = "There was a problem retrieving your itinerary details.";
            })
            .finally(() => { working.value = false; });
    }

    function datesArray(start, end) {
        let result = [], current = start;
        while (current <= end) {
            result.push(current) && (current = new Date(current)) && current.setDate(current.getDate() + 1);
        }
        return result;
    }

    const itineraryDays = computed(() => {
        let activities = null;
        let days = datesArray(new Date(itinerary.value.reservation.start), new Date(itinerary.value.reservation.end));

        if (itinerary.value.reservation.activities) {
            activities = itinerary.value.reservation.activities.slice();
            activities.sort(function (a, b) { return new Date(a.start).getTime() - new Date(b.start).getTime() });
        }

        const result = days.reduce(function (acc, cur, i) {

            let dayActivities = [];
            if (activities) {
                dayActivities = activities.filter(function (activity) {
                    return new Date(activity.start).toDateString() == cur.toDateString();
                });
            }

            acc[i] = { id: i, date: cur, activities: dayActivities };
            return acc;
        }, {});
        return result;
    })

    onMounted(() => {
        fetchData();
    })
</script>

<template>
    <div class="container">
        <div class="row">
            <div class="col-12 py-auto text-center">
                <h1>Your Itinerary</h1>
                <p class="working" v-show="working">Retrieving your itinerary details...</p>
                <p class="error" v-show="pageError">{{ pageError }}</p>
                <div v-if="itinerary">
                    <p class="mb-1 duration">{{new Date(itinerary.reservation.start).toLocaleDateString('en-gb', { weekday:"long", month:"long", day:"numeric"})}} - {{new Date(itinerary.reservation.end).toLocaleDateString('en-gb', { weekday:"long", month:"long", day:"numeric", year:"numeric"})}}</p>
                    <p><strong>Adults:</strong> {{itinerary.reservation.adults}} <strong v-show="itinerary.reservation.children != null">Children:</strong> {{itinerary.reservation.children}}</p>
                    <div class="book-links">
                        <a :href="itinerary.espUri" class="btn">Book Activities</a>
                        <!--
        <a href="https://book.rguest.eu/onecart/seat/restaurantsList/10131/TheMole" class="btn" target="_blank">Book Restaurant</a>
        -->
        </div>

                    <Splide class="itin-days py-2" :tag="section" :options="{ rewind: true, perPage: 3, gap: '15px', pagination: false, breakpoints: { 992: { perPage: 2 }, 768: { perPage: 1 }} }" aria-label="Itinerary Days">
                        <SplideSlide v-for="day of itineraryDays" v-bind:key="day.id">
                            <p v-bind:key="day.id" class="date">{{day.date.toLocaleDateString('en-gb', { weekday:"long", month:"numeric", day:"numeric"})}}</p>
                            <div class="itin-items" v-bind:key="day.id">
                                <p class="no-activities" v-if="day.activities.length == 0">You do not have any activities booked!<br /></p>
                                <div class="itin-item" v-for="activity of day.activities" v-bind:key="activity.id">
                                    <p>{{Intl.DateTimeFormat('en', { hour: 'numeric', minute: 'numeric' }).format(new Date(activity.start))}}</p>
                                    <div>
                                        <h2>{{activity.name}}</h2>
                                        <p v-if="activity.location">{{activity.location}}</p>
                                        <p v-if="activity.description">{{activity.description}}</p>
                                        <p v-if="activity.participants">{{activity.participants.length}} persons</p>
                                    </div>
                                </div>
                            </div>
                        </SplideSlide>
                    </Splide>
                    <!--
                    <div class="book-links">
                        <a :href="itinerary.espUri" class="btn">Book Activities</a>
                        <a href="https://book.rguest.eu/onecart/seat/restaurantsList/10131/TheMole" class="btn" target="_blank">Book Restaurant</a>
                        </div>
                        -->
                </div>
            </div>
        </div>
    </div>
</template>

<style>
    @import '@splidejs/vue-splide/css';

    .itin-days .splide__slide {
        background: #fff;
        min-height: 150px;
    }
    .itin-days .splide__slide > p.date {
        color: #fff;
        background: #342F24;
        margin: 0;
        padding: 10px;
    }    
    .itin-days .splide__slide h2 {
        font-size: 18px;
        margin-bottom: 10px;
    }
    .itin-days .splide__slide .no-activities {
        color: #777;
        padding: 20px;
        font-size: 1.1em;
    }
    .itin-days .splide__slide .itin-item {
        display: flex;
        gap: 15px;
        padding: 20px;
        text-align: left;
    }
    .itin-days .splide__slide .itin-item:nth-child(even) {
        background: #E8E0DF;
    }

    .book-links {
        display: flex;
        gap: 10px;
        justify-content: center;
    }

    .splide__arrow {
        top: 36px;
        background: none !important;
    }
    .splide__arrow svg {
        fill: #fff;
    }

    p.duration {
        font-size: 1.1em;
    }
    p.working {
        font-size: 1.2em;
    }
    p.working:after {
        content: '';
        display: block;
        width: 30px;
        height: 30px;
        border: 4px solid #d2a14b;
        border-bottom-color: transparent;
        border-radius: 50%;
        box-sizing: border-box;
        animation: rotation 1s linear infinite;
        margin: 20px auto;
    }
    p.error {
        color: #ae2b2b;
        margin-top: 10px;
    }
    @media (min-width: 768px) {
        .splide__arrow {
            top: 45px;
        }
    }
    @keyframes rotation {
        0% {
            transform: rotate(0deg);
        }

        100% {
            transform: rotate(360deg);
        }
    }
</style>